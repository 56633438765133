<template>
  <div class="detail">
    <div class="audio-wrapper">
      <audio :src="baseUrl" controls="controls"></audio>
      <!-- <div class="audio-left">![](image/play.png)</div> -->
    </div>
    <div class="detail-header">
      <i class="el-icon-back" @click="getBack"></i>
      <img :src="imgUrl" />
    </div>
    <div :class="[currentData.extra ? 'title-left title' : 'title']"><span class="title-name">{{currentData.name}}</span><span v-if="currentData.extra">{{currentData.extra}}</span></div>
    <div class="main">
      <div class="text-p" v-for="(o,i) in currentData.content" :key="i">
        {{o}}
      </div>
    </div>

    <!-- <div class="btn-group">
      <el-button type="primary" class="cus-btn" icon="el-icon-arrow-left" :disabled="id==1" @click.native="goPrev">上一个</el-button>
      <el-button type="primary" class="cus-btn btn-next" :disabled="id==18" @click.native="goNext">下一个<i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {
      currentData: {},
      baseUrl: '',
      imgUrl: '',
      id: this.$route.params.id
    }
  },
  mounted () {
    let currentData = this.listData[this.$route.params.id - 1];
    this.currentData = currentData;
    this.baseUrl = require(`@/assets/audio/${currentData.id}.mp3`)
    this.imgUrl = require(`@/assets/${currentData.id}.png`)
  },
  methods: {
    goPrev: function() {
      this.$router.replace(`/detail/${this.currentData.id - 1}`)
    },
    goNext: function() {
      this.$router.replace(`/detail/${this.currentData.id + 1}`)
    },
    getBack: function () {
      this.$router.go(-1);
    }
  },
  watch: {
      $route(){
          this.id = this.$route.params.id;
          let currentData = this.listData[this.id - 1];
          this.currentData = currentData;
          this.baseUrl = `https://supplier-wx-mall.oss-cn-hangzhou.aliyuncs.com/audio/${currentData.id}.mp3`
      },
  }
}
</script>

<style lang="less" src="./Detail.less"></style>